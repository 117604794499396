<template>
  <b-modal
    id="category-add-modal"
    centered
    :title="isEdit ? $t('editing_category') : $t('adding_category')"
    :ok-title="isEdit ? $t('update') : $t('append')"
    :cancel-title="this.$t('cancel')"
    @ok="onSubmit"
    @show="onShow"
  >
    <b-row>
      <b-col cols="6">
        <b-form-group :label="this.$t('naming_uz') + ':'">
          <b-form-input
            v-model="modalData.name"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('naming_ru') + ':'">
          <b-form-input
            v-model="modalData.russian_name"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('naming_en') + ':'">
          <b-form-input
            v-model="modalData.english_name"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('priority') + ':'">
          <b-form-input
            v-model="modalData.priority"
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="text-cetner">
          <div
            class="drag_place my-2"
            v-if="!modalData.showImage || modalData.showImage === 'http://185.17.66.176:60402/img/Test image/'"
          >
            {{ this.$t('browse image') }}
          </div>

          <b-img
            v-else
            v-model="modalData.image"
            class="d-block mx-auto w-100 px-4 py-2"
            :src="modalData.showImage"
          />
        </b-form-group>
        <b-form-group>
          <b-form-file
            id="wildcard"
            accept="image/*"
            @input="loadImg"
            v-model="modalData.downImage"
          />
        </b-form-group>
      </b-col>
      <div class="d-flex justify-content-start mt-1"></div>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BModal,
  BForm,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BFormGroup,
  BImg,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormGroup,
    BImg,
    BRow,
    BCol,
  },

  data() {
    return {
      modalData: {
        name: null,
        russian_name: null,
        english_name: null,
        priority: null,
        image: null,
        downImage: null,
        showImage: null,
      },
    };
  },

  props: {
    editData: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("category", ["GET_CATEGORIES"]),
  },

  methods: {
    ...mapActions("category", ["ADD_CATEGORY", "UPDATE_CATEGORY"]),

    onSubmit() {
      let formData = new FormData();
      if (this.modalData?.image) {
        if (typeof this.modalData.image !== "string") {
          formData.append("image", this.modalData.downImage);
        }
      }
      formData.append("name", this.modalData.name);
      formData.append("russian_name", this.modalData.russian_name);
      formData.append("english_name", this.modalData.english_name);
      formData.append("priority", this.modalData.priority);

      if (this.editData?.id) {
        formData.append("category_id", this.editData.id);
      }

      if (this.isEdit) {
        this.UPDATE_CATEGORY(formData)
          .then(() => {
            this.$bvToast.toast(this.$t("toast.success"), {
              title: this.$t("success").toUpperCase(),
              variant: "success",
              solid: true,
            });
            this.$emit("restart");
          })
          .catch(() => {
            this.$bvToast.toast(this.$t("toast.error"), {
              title: this.$t("error").toUpperCase(),
              variant: "danger",
              solid: true,
            });
          });
      } else {
        this.ADD_CATEGORY(formData)
          .then(() => {
            this.$bvToast.toast(this.$t("toast.success"), {
              title: this.$t("success").toUpperCase(),
              variant: "success",
              solid: true,
            });
            this.$emit("restart");
          })
          .catch(() => {
            this.$bvToast.toast(this.$t("toast.error"), {
              title: this.$t("error").toUpperCase(),
              variant: "danger",
              solid: true,
            });
          });
      }

      // this.$refs["category-add-modal"].hide();
    },

    onCancel() {
      this.$refs["category-add-modal"].hide();
    },

    loadImg() {
      this.modalData.image = this.modalData.downImage;
      this.modalData.showImage = URL.createObjectURL(this.modalData.downImage);
    },

    onShow() {
      if (this.isEdit) {
        this.modalData.name = this.editData.name;
        this.modalData.russian_name = this.editData.russian_name;
        this.modalData.english_name = this.editData.english_name;
        this.modalData.priority = this.editData.priority;
        this.modalData.image = this.editData.image;
        this.modalData.showImage = this.editData.image_adress;
      } else {
        this.modalData.name = null;
        this.modalData.russian_name = null;
        this.modalData.english_name = null;
        this.modalData.priority = null;
        this.modalData.image = null;
        this.modalData.showImage = null;
      }
    },
  },
};
</script>

<style>
.drag_place {
  width: 100%;
  height: 140px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  line-height: 140px;
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}
</style>